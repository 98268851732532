import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, } from 'rxjs';

export interface IPeriodoPesquisa {
  dtInicialPesquisa: string;         // formato yyyyMMdd
  dtFinalPesquisa: string;           // formato yyyyMMdd
}

@Injectable({
  providedIn: 'root'
})
export class ControlePeriodoAppService {

  private periodoPesquisaSubject$ = new BehaviorSubject<IPeriodoPesquisa>({} as IPeriodoPesquisa);
  PeriodoPesquisa: IPeriodoPesquisa = {} as IPeriodoPesquisa;

  constructor() {}

  publicarNovoPeriodo(novoPeriodo: IPeriodoPesquisa) {
    if (localStorage.getItem('PasseioJaApp:PeriodoPesquisa')) {
      localStorage.removeItem('PasseioJaApp:PeriodoPesquisa')
    }
    localStorage.setItem('PasseioJaApp:PeriodoPesquisa', JSON.stringify(novoPeriodo));
    this.PeriodoPesquisa = novoPeriodo;
    this.periodoPesquisaSubject$.next(novoPeriodo);
    // console.log('publicou', novoPeriodo )
  }

  public observablePeriodoPesquisa(): Observable<IPeriodoPesquisa> {
    return this.periodoPesquisaSubject$.asObservable();
  }

  obterPeriodoPesquisa(): IPeriodoPesquisa {
    return this.PeriodoPesquisa;
  }

}
